.App {
  display:flex;
  justify-content:center;
  align-items:center;

  background-color: #1e2129;
  color: #ffffff;
  font-size: 22px;

  width: 100%;
  height: 100vh;
}

.App-title {
  font-size: 36px;
}

.App-hr {
  width: 70%;
  height: 3px;
  background-color: #ffffff;
}

.App-links {
  margin-top: 30%;
  margin-left: 10%;
}

.App-link-icon {
  width: 60px;
  text-align: center;
  display: inline-block;
}

.App-sub-text {
  color: #3c4152;
}

/* unvisited link */
a:link, a:visited, a:hover, a:active {
  color: #ffffff;
  text-decoration: none;
}

